<template>
  <div class="block">
    <div class="row" :class="{'row--del': action.isAgentBlocked}">
      <div class="cell cell__num">{{ num + 1 }}</div>
      <div class="cell cell__name">
        <div class="user__icon">{{ action.agentName && action.agentName[0] }}</div>
        <div class="user__name">{{ action.agentName }}</div>
      </div>
      <div class="cell cell__type">
        <div class="cell__type-inner" v-if="action.actionType === 1">
          <i class="ri-home-6-fill"></i>
          <span>Показ</span>
        </div>
        <div class="cell__type-inner" v-if="action.actionType === 2">
          <i class="ri-eye-fill"></i>
          <span>Огляд</span>
        </div>
        <div class="cell__type-inner" v-if="action.actionType === 4">
          <i class="ri-phone-fill"></i>
          <span>Дзвінок</span>
        </div>
        <div class="cell__type-inner" v-if="action.actionType === 5">
          <i class="ri-money-dollar-box-fill"></i>
          <span>Завдаток</span>
        </div>
        <div class="cell__type-inner" v-if="action.actionType === 6">
          <i class="ri-government-fill"></i>
          <span>Нотаріус</span>
        </div>
        <div class="cell__type-inner" v-if="action.actionType === 7">
          <i class="ri-key-2-fill"></i>
          <span>Угода по оренді</span>
        </div>
        <div class="cell__type-inner" v-if="action.actionType === 8">
          <i class="ri-article-fill"></i>
          <span v-if="action.productionType === 6">Розклейка</span>
          <span v-else-if="action.productionType === 7">Банер</span>
          <span v-else-if="action.productionType === 8">П-ня сусідів</span>
          <span v-else>{{ action.productionTypeName }}</span>
        </div>
      </div>
      <div class="cell cell__date">{{ action.actionAt | date }}</div>
      <div class="cell cell__time">{{ action.time }}</div>
      <div class="cell cell__prop">
        <div v-if="action.propertyType === 4" class="cell__prop-inner">
          <i class="ri-user-fill"></i>
          <span>{{ action.propertyRelated.title }}</span>
        </div>
        <div
          v-else-if="(action.propertyType === 2 || action.propertyType === 3 || action.actionType === 8) && action.propertyRelated"
          class="cell__prop-inner cell-prop"
          @click.stop="openProp"
        >
          <i v-if="action.propertyRelated && action.propertyRelated.type === 'house'" class="ri-home-5-fill"></i>
          <i
            v-else-if="action.propertyRelated && action.propertyRelated.type === 'apartment'"
            class="ri-building-4-fill"
          ></i>
          <i v-else-if="action.propertyRelated && action.propertyRelated.type === 'commerce'" class="ri-store-fill"></i>
          <span>{{ action.propertyRelatedID }}</span>
          <span style="white-space: pre" v-if="action.propertyType === 3"> iAH</span>
        </div>
        <span style="white-space: pre" v-else-if="action.propertyType === 2 && !action.propertyRelated"> ID</span>
        <span style="white-space: pre" v-else-if="action.propertyType === 3 && !action.propertyRelated"> iAH</span>
        <span v-else>—</span>
      </div>
      <div class="cell cell__buyer">
        <div v-if="action.buyerType === 4" class="cell__prop-inner">
          <i class="ri-user-fill"></i>
          <span>{{ action.buyerRelated.title }}</span>
        </div>
        <div
          v-else-if="(action.buyerType === 2 || action.buyerType === 3) && action.buyerRelated"
          class="cell__prop-inner cell-buy"
          @click.stop="openBuyer"
        >
          <i v-if="action.buyerRelated && action.buyerRelated.type === 'house'" class="ri-home-5-fill"></i>
          <i v-else-if="action.buyerRelated && action.buyerRelated.type === 'apartment'" class="ri-building-4-fill"></i>
          <i v-else-if="action.buyerRelated && action.buyerRelated.type === 'commerce'" class="ri-store-fill"></i>
          <span>{{ action.buyerRelatedID }} </span>
          <span style="white-space: pre" v-if="action.buyerType === 3"> iAH</span>
        </div>
        <span style="white-space: pre" v-else-if="action.buyerType === 2 && !action.buyerRelated"> ID</span>
        <span style="white-space: pre" v-else-if="action.buyerType === 3 && !action.buyerRelated"> iAH</span>
        <span v-else>—</span>
      </div>
      <div class="cell cell__result">
        <div v-if="action.actionType === 8" class="cell cell__result">
          <div class="indicator indicator--dot indicator--green">{{ action.productionNumber }}</div>
        </div>
        <div v-else>
          <div v-if="action.result === 1" class="indicator indicator--dot indicator--green">Згода</div>
          <div v-else-if="action.result === 2" class="indicator indicator--dot indicator--red">Відмова</div>
          <div v-else-if="action.result === 3" class="indicator indicator--dot indicator--blue">План</div>
          <div v-else-if="action.result === 4" class="indicator indicator--dot indicator--orange">Думають</div>
        </div>
      </div>
      <div v-if="!action.isAgentBlocked" class="cell cell__name" @click.stop.prevent>
        <Multiselect
          class="mSelect-dropdown"
          :options="list"
          :searchable="false"
          :show-labels="false"
          :reset-after="true"
          :value="item"
          placeholder
          @select="actions"
        />
      </div>
    </div>
    <div class="card" :class="{'card--del': action.isAgentBlocked}">
      <div class="card__row">
        <div class="cell cell__name">
          <div class="user__icon">{{ action.agentName && action.agentName[0] }}</div>
          <div class="user__name">{{ action.agentName }}</div>
        </div>
        <div class="cell cell__result">
          <div v-if="action.actionType === 8" class="cell cell__result">
            <div class="indicator indicator--dot indicator--green">{{ action.productionNumber }}</div>
          </div>
          <div v-else>
            <div v-if="action.result === 1" class="indicator indicator--dot indicator--green">Згода</div>
            <div v-else-if="action.result === 2" class="indicator indicator--dot indicator--red">Відмова</div>
            <div v-else-if="action.result === 3" class="indicator indicator--dot indicator--blue">План</div>
            <div v-else-if="action.result === 4" class="indicator indicator--dot indicator--orange">Думають</div>
          </div>
        </div>
      </div>
      <div class="card__row" v-if="action.actionType !== 8">
        <div class="card__cell card__cell--title">Тип</div>
        <div class="cell cell__type">
          <div class="cell__type-inner" v-if="action.actionType === 1">
            <i class="ri-home-6-fill"></i>
            <span>Показ</span>
          </div>
          <div class="cell__type-inner" v-if="action.actionType === 2">
            <i class="ri-eye-fill"></i>
            <span>Огляд/Зустріч</span>
          </div>
          <div class="cell__type-inner" v-if="action.actionType === 4">
            <i class="ri-phone-fill"></i>
            <span>Дзвінок по рекламі</span>
          </div>
          <div class="cell__type-inner" v-if="action.actionType === 5">
            <i class="ri-money-dollar-box-fill"></i>
            <span>Завдаток</span>
          </div>
          <div class="cell__type-inner" v-if="action.actionType === 6">
            <i class="ri-government-fill"></i>
            <span>Нотаріус</span>
          </div>
        </div>
      </div>
      <div v-if="action.actionType === 8" class="card__row">
        <div class="card__cell card__cell--title">Тип продукції</div>
        <div class="cell cell__type">{{ action.productionTypeName }}</div>
      </div>
      <div class="card__row">
        <div class="card__cell card__cell--title">Дата і час</div>
        <div class="card__cell card__cell--datetime">
          <i class="ri-calendar-fill"></i>
          <span class="card__cell-date">{{ action.actionAt | date }}</span>
          <i class="ri-time-fill"></i>
          <span class="card__cell-time">{{ action.time }}</span>
        </div>
      </div>
      <div class="card__row">
        <div class="card__cell card__cell--title">Об’єкт/Пок.</div>
        <div class="card__cell--two">
          <div class="cell cell__prop">
            <div v-if="action.propertyType === 4" class="cell__prop-inner">
              <i class="ri-user-fill"></i>
              <span>{{ action.propertyRelated.title }}</span>
            </div>
            <div
              v-else-if="(action.propertyType === 2 || action.propertyType === 3 || action.actionType === 8) && action.propertyRelated"
              class="cell__prop-inner cell-prop"
              @click.stop="openProp"
            >
              <i v-if="action.propertyRelated && action.propertyRelated.type === 'house'" class="ri-home-5-fill"></i>
              <i
                v-else-if="action.propertyRelated && action.propertyRelated.type === 'apartment'"
                class="ri-building-4-fill"
              ></i>
              <i
                v-else-if="action.propertyRelated && action.propertyRelated.type === 'commerce'"
                class="ri-store-fill"
              ></i>
              <span>{{ action.propertyRelatedID }}</span>
              <span style="white-space: pre" v-if="action.propertyType === 3"> iAH</span>
            </div>
            <span style="white-space: pre" v-else-if="action.propertyType === 2 && !action.propertyRelated"> ID</span>
            <span style="white-space: pre" v-else-if="action.propertyType === 3 && !action.propertyRelated"> iAH</span>
            <span v-else>—</span>
          </div>
          <div class=""> / </div>
          <div class="cell cell__buyer">
            <div v-if="action.buyerType === 4" class="cell__prop-inner">
              <i class="ri-user-fill"></i>
              <span>{{ action.buyerRelated.title }}</span>
            </div>
            <div
              v-else-if="(action.buyerType === 2 || action.buyerType === 3) && action.buyerRelated"
              class="cell__prop-inner cell-buy"
              @click.stop="openBuyer"
            >
              <i v-if="action.buyerRelated && action.buyerRelated.type === 'house'" class="ri-home-5-fill"></i>
              <i
                v-else-if="action.buyerRelated && action.buyerRelated.type === 'apartment'"
                class="ri-building-4-fill"
              ></i>
              <i v-else-if="action.buyerRelated && action.buyerRelated.type === 'commerce'" class="ri-store-fill"></i>
              <span>{{ action.buyerRelatedID }} </span>
              <span style="white-space: pre" v-if="action.buyerType === 3"> iAH</span>
            </div>
            <span style="white-space: pre" v-else-if="action.buyerType === 2 && !action.buyerRelated"> ID</span>
            <span style="white-space: pre" v-else-if="action.buyerType === 3 && !action.buyerRelated"> iAH</span>
            <span v-else>—</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import ModalUploadAction from '@/views/Actions/UploadAction'
import InfoObject from '@/views/Objects/Info'
import InfoBuyer from '@/views/Buyers/Info'

export default {
  name: 'Action',
  props: ['action', 'num'],
  components: {
    Multiselect
  },
  data: () => ({
    list: ['Редагувати', 'Видалити'],
    item: ''
  }),
  methods: {
    actions(option) {
      if (option === 'Редагувати') {
        const style = this.$modal.styles
        this.$modal.show(
          ModalUploadAction,
          {action: this.action},
          {...style, name: 'modalUploadAction'},
          {'before-open': this.$modal.open, 'before-close': this.$modal.close}
        )
      } else if (option === 'Видалити') {
        this.$store.dispatch('delActions', this.action.id)
      }
    },
    async openProp() {
      const obj = this.action.propertyRelated
      let loadObj = Object
      if (obj.type === 'apartment') {
        await this.$store.dispatch('objects/apartments/fetchApartment', obj.id)
        loadObj = this.$store.getters['objects/apartments/apartment']
      } else if (obj.type === 'house') {
        await this.$store.dispatch('objects/houses/fetchHouse', obj.id)
        loadObj = this.$store.getters['objects/houses/house']
      } else if (obj.type === 'commerce') {
        await this.$store.dispatch('objects/commerce/fetchCommerce', obj.id)
        loadObj = this.$store.getters['objects/commerce/oneCommerce']
      }
      this.$modal.display(InfoObject, {info: loadObj}, {name: 'InfoObject'})
    },
    async openBuyer() {
      const buyer = this.action.buyerRelated
      let loadBuy = Object
      if (buyer.type === 'apartment') {
        await this.$store.dispatch('buyers/apartments/fetchApartment', buyer.id)
        loadBuy = this.$store.getters['buyers/apartments/apartment']
      } else if (buyer.type === 'house') {
        await this.$store.dispatch('buyers/houses/fetchHouse', buyer.id)
        loadBuy = this.$store.getters['buyers/houses/house']
      } else if (buyer.type === 'commerce') {
        await this.$store.dispatch('buyers/commerce/fetchCommerce', buyer.id)
        loadBuy = this.$store.getters['buyers/commerce/oneCommerce']
      }
      this.$modal.display(InfoBuyer, {buyer: loadBuy}, {name: 'InfoBuyer'})
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
